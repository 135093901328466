<template>
  <div>
    <SpHeader :text="text" />
    <LinkHeader
      :left="headerLink.left"
      :right="headerLink.right"
      :show="headerLink.show"
    />
    <div class="toppage-tab-contents-on-site">
      <div class="tabPage tabPage4">
        <TmpMessage v-if="tmp" />
        <ul
          class="childtabs"
          id="restaurant"
          :class="{ 'fixed --top-0': tabTtlActive }"
        >
          <li class="tab">
            <a href="#fifth">5階</a>
          </li>
          <li class="tab">
            <a href="#forth">4階</a>
          </li>
          <li class="tab">
            <a href="#third">3階</a>
          </li>
          <li class="tab">
            <a href="#second">2階</a>
          </li>
          <li class="tab">
            <a href="#first">1階</a>
          </li>
          <li class="tab">
            <a href="#b-first">B1階</a>
          </li>
        </ul>
        <LegendToilet :class="{ fixed: tabTtlActive, tmp: tmp }" />
        <div v-for="(value, key) in floorData" :key="key">
          <span
            class="floor-container --2"
            :id="value.floor"
            :class="{ fixed: value.fixed ? tabTtlActive : null }"
          ></span>
          <div class="content__label--bar">
            <img
              v-lazy="
                require(`@/assets/img/sp/toilet/subtitle_toilet_${value.id}.png`)
              "
              :srcset="`${require(`@/assets/img/sp/toilet/subtitle_toilet_${value.id}@2x.png`)} 2x`"
              :width="value.subtitle.width"
              :height="value.subtitle.height"
              :class="`subtitle_img_${value.id}`"
              :alt="`${value.id}階`"
            />
          </div>
          <ToiletList
            :dataList="value.dataList"
            :statusList="value.statusListFloor"
            :className="`${classNameFloor}${value.id}`"
          />
        </div>
      </div>
    </div>
    <SpFooter />
    <ModalOutOfTerm :isShow="isModalShow" @from-modal="closeModal" />
  </div>
</template>

<script>
import SpFooter from "../../components/common/Footer.vue";
import SpHeader from "../../components/common/Header.vue";
import TmpMessage from "../../components/message/TmpMessage.vue";
import LinkHeader from "../../components/detail/LinkHeader.vue";
import LegendToilet from "../../components/detail/LegendToilet.vue";
import ToiletList from "../../components/list/ToiletList.vue";
import ModalOutOfTerm from "../../components/detail/ModalOutOfTerm.vue";

export default {
  name: "SpToilet",
  components: {
    SpHeader,
    TmpMessage,
    SpFooter,
    LinkHeader,
    LegendToilet,
    ToiletList,
    ModalOutOfTerm,
  },
  created() {
    this.testId = this.$router.currentRoute.params.test;
    this.getData(this.testId);
    this.checkMessage();
    this.checkHeaderLink();
    this.checkParkwins();
  },
  data() {
    return {
      testId: null,
      text: "toilet",
      tmp: false,
      tmpHeight: 0,
      tabTtlActive: false,
      classNameFloor: "toilet",
      toiletList: [],
      isModalShow: false,
      headerLink: {
        left: {
          external: false,
          to: "/tokyo/shops/",
          type: "shop",
          icon: { name: "shop", width: "24", height: "25" },
          text: {
            title: "飲食店などの\n混雑状況",
          },
        },
        right: {},
        // アンケート実施日
        survey: {
          external: true,
          to: "https://docs.google.com/forms/d/e/1FAIpQLSfqP6ijjDDRN2UFYyyP0blvMNT8Y7oTskYmJtYu4Ee5iTxK0g/viewform?usp=sf_link",
          type: "survey",
          icon: { name: "gift", width: "17", height: "17" },
          text: {
            title: "アンケートはこちら",
            sub: "回答するとデジタルギフトが当たる",
          },
        },
        // 駐車場混雑
        parking: {
          external: false,
          to: "/tokyo/parking/",
          type: "parking",
          icon: { name: "car", width: "24", height: "20" },
          text: {
            title: "駐車場の混雑状況",
          },
        },
        show: false,
      },
      floorData: [
        {
          id: "5",
          floor: "fifth",
          fixed: false,
          subtitle: {
            width: "37",
            height: "37",
          },
          dataList: {
            map: {
              image: {
                src: "toilet/map/map_toilet_5",
                width: "355",
                height: "299",
                alt: "5階トイレマップ",
              },
            },
          },
          statusListFloor: {
            id: "floor",
            column: "column2",
            path: "floor/set",
            data: [
              {
                category: "toilet",
                class: "--5-1 --bottom",
                alt: "GW1",
                info: { alt: "GW1", img: "status-1" },
              },
              {
                category: "toilet",
                class: "--5-2 --bottom",
                alt: "GW2",
                info: { alt: "GW2", img: "status-1" },
              },
              {
                category: "toilet",
                class: "--5-3 --bottom",
                alt: "GW3",
                info: { alt: "GW3", img: "status-1" },
              },
              {
                category: "toilet",
                class: "--5-4 --bottom",
                alt: "GW4",
                info: { alt: "GW4", img: "status-1" },
              },
            ],
          },
        },
        {
          id: "4",
          floor: "forth",
          fixed: false,
          subtitle: {
            width: "37",
            height: "37",
          },
          dataList: {
            map: {
              image: {
                src: "toilet/map/map_toilet_4",
                width: "355",
                height: "329",
                alt: "4階トイレマップ",
              },
            },
          },
          statusListFloor: {
            id: "floor",
            column: "column2",
            path: "floor/set",
            data: [
              {
                category: "toilet",
                class: "--4-1 --top",
                alt: "GW27",
                info: { alt: "GW27", img: "status-1" },
              },
              {
                category: "toilet",
                class: "--4-2 --top",
                alt: "GW28",
                info: { alt: "GW28", img: "status-1" },
              },
              {
                category: "toilet",
                class: "--4-3 --bottom",
                alt: "GW5",
                info: { alt: "GW5", img: "status-1" },
              },
              {
                category: "toilet",
                class: "--4-4 --bottom",
                alt: "GW6",
                info: { alt: "GW6", img: "status-1" },
              },
              {
                category: "toilet",
                class: "--4-5 --bottom",
                alt: "GW7",
                info: { alt: "GW7", img: "status-1" },
              },
              {
                category: "toilet",
                class: "--4-6 --bottom",
                alt: "GW8",
                info: { alt: "GW8", img: "status-1" },
              },
            ],
          },
        },
        {
          id: "3",
          floor: "third",
          fixed: false,
          subtitle: {
            width: "37",
            height: "37",
          },
          dataList: {
            map: {
              image: {
                src: "toilet/map/map_toilet_3",
                width: "355",
                height: "321",
                alt: "3階トイレマップ",
              },
            },
          },
          statusListFloor: {
            id: "floor",
            column: "column2",
            path: "floor/set",
            data: [
              {
                category: "toilet",
                class: "--3-1 --top",
                alt: "GW29",
                info: { alt: "GW29", img: "status-1" },
              },
              {
                category: "toilet",
                class: "--3-2 --top",
                alt: "GW30",
                info: { alt: "GW30", img: "status-1" },
              },
              {
                category: "toilet",
                class: "--3-3 --bottom",
                alt: "GW9",
                info: { alt: "GW9", img: "status-1" },
              },
              {
                category: "toilet",
                class: "--3-4 --bottom",
                alt: "GW10",
                info: { alt: "GW10", img: "status-1" },
              },
              {
                category: "toilet",
                class: "--3-5 --bottom",
                alt: "GW11",
                info: { alt: "GW11", img: "status-1" },
              },
            ],
          },
        },
        {
          id: "2",
          floor: "second",
          fixed: false,
          subtitle: {
            width: "37",
            height: "37",
          },
          dataList: {
            map: {
              image: {
                src: "toilet/map/map_toilet_2",
                width: "355",
                height: "324",
                alt: "2階トイレマップ",
              },
            },
          },
          statusListFloor: {
            id: "floor",
            column: "column2",
            path: "floor/set",
            data: [
              {
                category: "toilet",
                class: "--2-1 --top",
                alt: "GW31",
                info: { alt: "GW31", img: "status-1" },
              },
              {
                category: "toilet",
                class: "--2-2 --top",
                alt: "GW32",
                info: { alt: "GW32", img: "status-1" },
              },
              {
                category: "toilet",
                class: "--2-3 --mid2",
                alt: "GW15",
                info: { alt: "GW15", img: "status-1" },
              },
              {
                category: "toilet",
                class: "--2-4 --mid2",
                alt: "GW17",
                info: { alt: "GW17", img: "status-1" },
              },
              {
                category: "toilet",
                class: "--2-5 --mid2",
                alt: "GW18",
                info: { alt: "GW18", img: "status-1" },
              },
              {
                category: "toilet",
                class: "--2-6 --mid2",
                alt: "GW20",
                info: { alt: "GW20", img: "status-1" },
              },
              {
                category: "toilet",
                class: "--2-7 --bottom",
                alt: "GW12",
                info: { alt: "GW12", img: "status-1" },
              },
              {
                category: "toilet",
                class: "--2-8 --bottom",
                alt: "GW13",
                info: { alt: "GW13", img: "status-1" },
              },
              {
                category: "toilet",
                class: "--2-9 --bottom",
                alt: "GW14",
                info: { alt: "GW14", img: "status-1" },
              },
              {
                category: "toilet",
                class: "--2-10 --bottom",
                alt: "GW16",
                info: { alt: "GW16", img: "status-1" },
              },
              {
                category: "toilet",
                class: "--2-11 --bottom",
                alt: "GW19",
                info: { alt: "GW19", img: "status-1" },
              },
            ],
          },
        },
        {
          id: "1",
          floor: "first",
          fixed: false,
          subtitle: {
            width: "37",
            height: "37",
          },
          dataList: {
            map: {
              image: {
                src: "toilet/map/map_toilet_1",
                width: "355",
                height: "345",
                alt: "1階トイレマップ",
              },
            },
          },
          statusListFloor: {
            id: "floor",
            column: "column2",
            path: "floor/set",
            data: [
              {
                category: "toilet",
                class: "--1-1 --top",
                alt: "GW33",
                info: { alt: "GW33", img: "status-1" },
              },
              {
                category: "toilet",
                class: "--1-2 --mid1",
                alt: "GW23",
                info: { alt: "GW23", img: "status-1" },
              },
              {
                category: "toilet",
                class: "--1-3 --mid1",
                alt: "GW25",
                info: { alt: "GW25", img: "status-1" },
              },
              {
                category: "toilet",
                class: "--1-4 --bottom",
                alt: "GW21",
                info: { alt: "GW21", img: "status-1" },
              },
              {
                category: "toilet",
                class: "--1-5 --bottom",
                alt: "GW22",
                info: { alt: "GW22", img: "status-1" },
              },
              {
                category: "toilet",
                class: "--1-6 --bottom",
                alt: "GW24",
                info: { alt: "GW24", img: "status-1" },
              },
              {
                category: "toilet",
                class: "--1-7 --bottom",
                alt: "GW26",
                info: { alt: "GW26", img: "status-1" },
              },
            ],
          },
        },
        {
          id: "b1",
          floor: "b-first",
          fixed: false,
          subtitle: {
            width: "54",
            height: "37",
          },
          dataList: {
            map: {
              image: {
                src: "toilet/map/map_toilet_b1",
                width: "355",
                height: "270",
                alt: "B1階トイレマップ",
              },
            },
          },
          statusListFloor: {
            id: "floor",
            column: "column2",
            path: "floor/set",
            data: [
              {
                category: "toilet",
                class: "--b1-1",
                alt: "GW34",
                info: { alt: "GW34", img: "status-1" },
              },
            ],
          },
        },
      ],
      env: process.env.VUE_APP_NODE_ENV,
      envBase: process.env.VUE_APP_2024_2nd_S3_BASE_URL,
      envBaseTmp: process.env.VUE_APP_TMP_S3_BASE_URL,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollWindow);
    this.updateData();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollWindow);
  },
  methods: {
    async checkMessage() {
      await this.$axios
        .get(`${this.envBaseTmp}message.json?` + new Date().getTime())
        .then((response) => {
          // console.log(response);
          this.tmp = response.data.message;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async checkHeaderLink() {
      await this.$axios
        .get(`${this.envBaseTmp}header_link.json?` + new Date().getTime())
        .then((response) => {
          // console.log(response);
          const today = new Date();
          const checkSurveyDisplay = (element) => {
            const surveyDay = new Date(element);
            return (
              today.getFullYear() === surveyDay.getFullYear() &&
              today.getMonth() === surveyDay.getMonth() &&
              today.getDate() === surveyDay.getDate()
            );
          };
          if (response.data.some(checkSurveyDisplay)) {
            this.headerLink.right = this.headerLink.survey;
          } else {
            this.headerLink.right = this.headerLink.parking;
          }
          this.headerLink.show = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async checkParkwins() {
      await this.$axios
        .get(`${this.envBaseTmp}parkwins.json?` + new Date().getTime())
        .then((response) => {
          // console.log(response);
          const today = new Date();
          const checkDateMatch = (element) => {
            const checkDay = new Date(element);
            return (
              today.getFullYear() === checkDay.getFullYear() &&
              today.getMonth() === checkDay.getMonth() &&
              today.getDate() === checkDay.getDate()
            );
          };
          if (this.checkSessionStorageAvailable()) {
            if (response.data.some(checkDateMatch)) {
              const todayStr =
                today.getFullYear().toString() +
                "/" +
                (today.getMonth() + 1).toString().padStart(2, "0") +
                "/" +
                today.getDate().toString().padStart(2, "0");
              if (sessionStorage.getItem("parkwins")) {
                if (sessionStorage.getItem("parkwins") !== todayStr) {
                  this.showModal();
                  sessionStorage.setItem("parkwins", todayStr);
                }
              } else {
                this.showModal();
                sessionStorage.setItem("parkwins", todayStr);
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getData(id) {
      const jsonURL =
        id === "99"
          ? `https://stg.jra-keibajo-konzatsu.jp/toilet.json?`
          : `${this.envBase}${this.env}/congestion/toilet.json?`;
      await this.$axios
        .get(jsonURL + new Date().getTime())
        .then((response) => {
          this.toiletList = response.data;
          this.searchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchData() {
      for (let item of Object.entries(this.floorData || {})) {
        const listData = item[1].statusListFloor.data;
        for (const i of listData) {
          const index = this.toiletList.find(
            (element) => element.gw_no === i.info.alt
          );
          if (index !== undefined) {
            // console.log(index);
            const status = index.status;
            i.info.name = index.gw_no;
            this.divideData(index, status, i);
          }
        }
      }
    },
    divideData(index, status, target) {
      target.info.name = index.gw_no;
      if (status === 2) {
        target.info.img = "status-5";
      } else if (status === 3) {
        target.info.img = "status-6";
      } else {
        this.setImg(index, target);
      }
    },
    setImg(index, target) {
      const level = index.congestion_level;
      if (level) {
        target.info.img = `status-${level}`;
      }
    },
    updateData() {
      if (this.checkSessionStorageAvailable()) {
        if (sessionStorage.getItem("tabIntervalId")) {
          clearInterval(sessionStorage.getItem("tabIntervalId"));
        }
      }
      this.timeout = setInterval(() => {
        this.getData(this.testId);
        this.checkMessage();
        // console.log("20秒");
        // }, 5000);
      }, 20000);
      if (this.checkSessionStorageAvailable()) {
        sessionStorage.setItem("tabIntervalId", this.timeout);
      }
    },
    checkSessionStorageAvailable() {
      try {
        sessionStorage.setItem("tabDummy", 1);
        sessionStorage.removeItem("tabDummy");
        return true;
      } catch {
        return false;
      }
    },
    scrollWindow() {
      if (this.tmp) {
        this.tmpHeight =
          document.getElementById("tmp-message").offsetHeight + 40;
      }
      const tab = 139 + this.tmpHeight;
      this.scroll = window.pageYOffset;
      if (tab <= this.scroll) {
        this.tabTtlActive = true;
      } else {
        this.tabTtlActive = false;
      }
    },
    showModal() {
      this.isModalShow = true;
      const $body = document.getElementsByTagName("body")[0];
      $body.classList.add("fixed");
    },
    closeModal() {
      this.isModalShow = false;
      const $body = document.getElementsByTagName("body")[0];
      $body.classList.remove("fixed");
    },
  },
};
</script>
